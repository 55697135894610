<template>
  <el-card
    shadow="never"
    class="aui-card--fill"
  >
    <div class="mod-sys__params">
      <el-form
        :inline="true"
        :model="dataForm"
      >
        <div class="flex-between">
          <div class="flex-start">
            <el-form-item>
              <el-input
                v-model="dataForm.searchStr"
                placeholder="搜索关键字"
                clearable
                @clear="handleFilter"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter()"
              >
                搜索
              </el-button>
            </el-form-item>
          </div>
          <div class="flex-end">
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="addOrUpdateHandle(1)"
              >
                新增
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="danger"
                @click="deleteHandle()"
              >
                删除
              </el-button>
            </el-form-item>
          </div>
        </div>

      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%;"
        @selection-change="dataListSelectionChangeHandle"
      >
        <el-table-column
          type="selection"
          width="50"
        />
        <el-table-column
          prop="code"
          :show-overflow-tooltip="true"
          label="模板编号"
        />
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="模板名称"
        />
        <el-table-column
          prop="describe"
          :show-overflow-tooltip="true"
          label="说明"
        />
        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="{row}">
            {{ row.status === 1 ? '编辑中' : '已完成' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateName"
          label="编辑人"
        />
        <el-table-column
          prop="updateTime"
          label="更新时间"
        />
        <el-table-column
          label="操作"
          width="120"
        >
          <template v-slot="scope">
            <el-link
              :underline="false"
              class="mr10"
              type="primary"
              @click="addOrUpdateHandle(2,scope.row)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              :underline="false"
              type="primary"
              class="mr10"
              @click="deleteHandle(scope.row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
            <el-link
              :underline="false"
              type="primary"
              @click="$router.push({path: '/orgSave', query: { id: scope.row.id}})"
            >
              <el-tooltip
                content="组织架构"
                placement="top"
                effect="light"
              >
                <i class="el-icon-s-home" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="page"
        :limit.sync="pageSize"
        @pagination="query"
      />
      <OrgSave
        ref="editDialog"
        @handleFilter="query"
      />
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import OrgSave from '@/views/template/org/OrgTemplateSave.vue'
import pagination from '@core/components/pagination/Pagination.vue'

export default {
  components: { pagination, OrgSave },
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/template/org/GetOrgTemplateByPage',
        getDataListIsPage: true,
        deleteURL: '/template/org/DelOrgTemplate',
        deleteIsBatch: true,
      },
    }
  },
  methods: {
    handleFilter() {
      this.page = 1
      this.getDataList()
    },
  },
}
</script>
